// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'

import { Navbar as NavbarLegacy } from 'components/navigation/navbar'
import { useAdminHotkeys } from 'components/project/admin-hotkeys'

import { ChannelSubNav } from './channel-sub-nav'
import { Navbar } from './navbar'

import { appsSelector, currentAppSelector, currentProjectAppsSelector } from 'com.batch.redux/app'
import { optionalCurrentProjectSelector, projectsSelector } from 'com.batch.redux/project.selector'
import { companyStateSelector, currentUserSelector } from 'com.batch.redux/user.selector'

export const NavbarConnected: React$AbstractComponent<{}, *> = React.memo((): React.Node => {
  // -----  redux
  const user = useSelector(currentUserSelector)
  const company = useSelector(companyStateSelector)
  const project = useSelector(optionalCurrentProjectSelector)
  const projects = useSelector(projectsSelector)
  const apps = useSelector(currentProjectAppsSelector)
  const allApps = useSelector(appsSelector)
  const app = useSelector(currentAppSelector) // return a new AppRecord with id=0 on project only page
  // -----  router
  const matchSettings = useMatch(
    '/:companyId/projects/:projectId/apps/:appId/settings/:activeTab/*'
  )
  const matchProjectForm = useMatch('/:companyId/projects/:projectId/campaigns/push/new')
  const matchProjectEmailForm = useMatch('/:companyId/projects/:projectId/automation/email/new')

  const matchProjectSettings = useMatch('/:companyId/projects/:projectId/settings/:activeTab')
  // -----  derived
  const isSettings = React.useMemo(() => {
    return !!matchSettings
  }, [matchSettings])

  useAdminHotkeys()

  const channel = React.useMemo(() => {
    if (project) {
      if (app.id === 0) {
        /* we need this check because on /project/projecId/campaigns/push/new we :
            - do not have an app (app.id==0)
            - do not want to route to /email campaign list
            when we get a real project campaign list this hack will go away
          */
        const projectHasOneChannelConfigured =
          project.smsConfigured || project.pushConfigured || project.emailConfigured
        return (window?.location?.pathname?.includes('/email') ||
          window?.location?.pathname?.includes('/automations')) &&
          projectHasOneChannelConfigured
          ? 'email'
          : { appId: project.appIds.first() }
      }
    }
    return { appId: app.id }
  }, [app.id, project])
  if (!project) return <NavbarLegacy showIntegrate={app.showIntegrate} app={app} />

  return (
    <React.Fragment>
      <Navbar
        company={company}
        project={project}
        user={user}
        channel={channel}
        allApps={allApps}
        projects={projects}
      />
      {!isSettings && !matchProjectForm && !matchProjectEmailForm && !matchProjectSettings && (
        <ChannelSubNav
          apps={apps}
          project={project}
          channel={app.id === 0 ? 'email' : { appId: app.id }}
          gotBorder={!isSettings}
          isTransparent
        />
      )}
    </React.Fragment>
  )
})
