import { combineReducers } from 'redux'

import app from './app'
import attribute from './attribute'
import attrValue from './attributeValue'
import audience from './audience'
import campaign from './campaign'
import cluster from './cluster'
import company from './company'
import content from './content'
import { senderIdentityReducer } from './corelogic/reducers/sender-identity.reducer'
import { dataCampaignReducer as dataCampaign } from './dataCampaign'
import project from './project'
import { QueryReducer as query } from './query/query'
import stat from './stat'
import { targetReducer } from './target/target'
import targeting from './targeting'
import template from './template'
import testDevice from './testDevice'
import theme from './theme'
import toaster from './toaster'
import user from './user'

import { audienceProfileReducer } from 'com.batch/audience/store/audience-profile.reducer'
import { labelsReducer } from 'com.batch/labels/store/labels.reducer'
import { messageReducer } from 'com.batch/message/store/message.reducer'
import { inlineEditorReducer } from 'com.batch/message-builder/store/inline-editor.reducer'
import { orchestrationReducer } from 'com.batch/orchestration/store/orchestration.reducer'
import { orchestrationAnalyticsReducer } from 'com.batch/orchestration-analytics/store/orchestration-analytics.reducer'
import { orchestrationListReducer } from 'com.batch/orchestration-list/store/orchestration-list.reducer'
import { profileReducer } from 'com.batch/profile/store/profile.reducer'
import { profilebaseReducer } from 'com.batch/profilebase/store/profilebase.reducer'

export default combineReducers({
  app,
  attribute,
  attrValue,
  audience,
  audienceProfile: audienceProfileReducer,
  campaign,
  cluster,
  company,
  content,
  message: messageReducer,
  dataCampaign,
  toaster,
  project,
  query,
  stat,
  targeting,
  orchestrationList: orchestrationListReducer,
  target: targetReducer,
  template,
  testDevice,
  theme,
  user,
  orchestration: orchestrationReducer,
  senderIdentity: senderIdentityReducer,
  profile: profileReducer,
  profilebase: profilebaseReducer,
  orchestrationAnalytics: orchestrationAnalyticsReducer,
  inlineEditor: inlineEditorReducer,
  projectLabels: labelsReducer,
})
