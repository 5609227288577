// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from 'components/common/grid'
import { Editor, InputWrapper } from 'components/form'

import { getEmailContentForSelectedLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

import { type MessageField } from 'com.batch/email/ui/pages/email-block'
import { updateEmailInfo } from 'com.batch/email/usecases/update-content'
import { MessageQuietTimes } from 'com.batch/shared/ui/component/message-quiet-time'

type EmailAdvancedSettingsProps = {
  messageId: string,
  stepId?: ?string,
  campaignFormMode?: boolean,
}

export const EmailAdvancedSettings = ({
  messageId,
  stepId,
  campaignFormMode,
}: EmailAdvancedSettingsProps): React.Node => {
  const dispatch = useDispatch()
  const getContent = useSelector(getEmailContentForSelectedLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const lang = useSelector(activeLanguageValueSelector)

  const updateInfo = React.useCallback(
    ({ field, value }: { field: MessageField, value: string | ?number }) => {
      dispatch(updateEmailInfo({ messageId, lang, field, value }))
    },
    [dispatch, lang, messageId]
  )

  const updateReplyTo = React.useCallback(
    (value: string) => {
      updateInfo({
        field: 'replyTo',
        value: value,
      })
    },
    [updateInfo]
  )

  return (
    <Grid template="1fr 1fr" style={{ padding: 20 }}>
      <InputWrapper label="Reply to" htmlFor="replyTo" style={{ paddingRight: 28 }}>
        <Editor
          id="replyTo"
          onChange={updateReplyTo}
          placeholder="support@yourcompany.com (optional)"
          value={content?.replyTo ?? ''}
        />
      </InputWrapper>
      {!campaignFormMode && <MessageQuietTimes channel="email" stepId={stepId} />}
    </Grid>
  )
}
