// @flow
import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Set, type RecordOf, type RecordFactory } from 'immutable'

import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'

type QuietTimesProps = {
  behavior: 'wait' | 'skip',
  startHour: number,
  endHour: number,
  startMin: number,
  endMin: number,
  quietDaysOfWeek: Set<number>,
  quietHoursTimePeriodDisabled: boolean,
  ...
}
export type QuietTimesRecord = RecordOf<QuietTimesProps>
export const QuietTimesFactory: RecordFactory<QuietTimesProps> = Immutable.Record(
  ({
    behavior: 'wait',
    startHour: 22,
    endHour: 8,
    startMin: 0,
    endMin: 0,
    quietDaysOfWeek: Immutable.Set<number>([]),
    quietHoursTimePeriodDisabled: true,
  }: QuietTimesProps)
)

export { getNodeId } from './id-generator'

type EventWithOptionalQueryProps = {
  name: string,
  query: string,
  requireMatchingInstanceId: boolean,
  ...
}
export type EventWithOptionalQueryRecord = RecordOf<EventWithOptionalQueryProps>
export const EventWithOptionalQueryFactory: RecordFactory<EventWithOptionalQueryProps> =
  Immutable.Record(
    ({
      name: '',
      query: '',
      requireMatchingInstanceId: true,
    }: EventWithOptionalQueryProps)
  )

export type JourneySettingsProps = {
  instanceId: string,
  hasInstanceId: boolean,
  start: ?Dayjs,
  hasStart: boolean,
  end: ?Dayjs,
  hasEnd: boolean,
  capping: number,
  hasCapping: boolean,
  hasGrace: boolean,
  gracePeriod: AgeRecord,
  entryEvents: List<EventWithOptionalQueryRecord>,
  quietTimes: QuietTimesRecord,
  hasQuietTimes: boolean,
  ...
}
export type JourneySettingsRecord = RecordOf<JourneySettingsProps>
export const JourneySettingsFactory: RecordFactory<JourneySettingsProps> = Immutable.Record(
  ({
    instanceId: '',
    hasInstanceId: false,
    start: null,
    hasStart: false,
    end: null,
    hasEnd: false,
    capping: 1,
    hasCapping: false,
    hasGrace: false,
    gracePeriod: AgeFactory({
      inputValue: '1',
      seconds: 3600,
      valid: true,
      unit: 'h',
      label: 'hour',
      fullText: '1 hour',
    }),
    entryEvents: new Immutable.List(),
    quietTimes: QuietTimesFactory(),
    hasQuietTimes: false,
  }: JourneySettingsProps)
)
export type NodeType = 'FINAL' | 'MESSAGE' | 'TIMER' | 'YESNO' | 'RANDOM'
export type NodeError =
  | 'INVALID_TIMER'
  | 'INCOMPLETE_MESSAGE'
  | 'SPLIT_EMPTY_BRANCHES'
  | 'SPLIT_RANDOM_DISTRIBUTION'
  | 'MISSING_TARGETING'
  | 'INVALID_END'

export type FinalNodeProps = {
  id: string,
  type: 'FINAL',
  errors: Set<NodeError>,
  ...
}
export type FinalNodeRecord = RecordOf<FinalNodeProps>
export const FinalNodeFactory: RecordFactory<FinalNodeProps> = Immutable.Record(
  ({ id: '', type: 'FINAL', errors: Immutable.Set() }: FinalNodeProps)
)

export type MessageNodeProps = {
  id: string,
  type: 'MESSAGE',
  channel: ChannelUntilCleanup,
  label: string,
  messageReference: string,
  nextNodeId: string,
  errors: Set<NodeError>,
  hasQuietTimes?: boolean,
  ...
}
export type MessageNodeRecord = RecordOf<MessageNodeProps>
export const MessageNodeFactory: RecordFactory<MessageNodeProps> = Immutable.Record(
  ({
    id: '',
    type: 'MESSAGE',
    channel: 'email',
    label: '',
    nextNodeId: '',
    messageReference: '',
    errors: Immutable.Set(),
    hasQuietTimes: true,
  }: MessageNodeProps)
)

type EventNextProps = {
  triggers: List<EventWithOptionalQueryRecord>,
  nextNodeId: string,
  ...
}
export type EventNextRecord = RecordOf<EventNextProps>
export const EventNextFactory: RecordFactory<EventNextProps> = Immutable.Record(
  ({ triggers: new Immutable.List(), nextNodeId: '' }: EventNextProps)
)

export type TimerMode = 'for' | 'before' | 'after' | 'until'

export type TimerNodeProps = {
  id: string,
  type: 'TIMER',
  mode: TimerMode,
  timerReference: string,
  timer: AgeRecord,
  waitUntilTime: WaitUntilTimeRecord,
  nextNodeId: string,
  onEvents: List<EventNextRecord>,
  errors: Set<NodeError>,
  ...
}

export type WaitUntilTimeProps = {
  hour: number,
  minute: number,
  daysOfWeek: Set<number>,
}

export type WaitUntilTimeRecord = RecordOf<WaitUntilTimeProps>
export const WaitUntilTimeFactory: RecordFactory<WaitUntilTimeProps> = Immutable.Record({
  hour: 11,
  minute: 0,
  daysOfWeek: Immutable.Set<number>([0, 1, 2, 3, 4, 5, 6]),
})

export type TimerNodeRecord = RecordOf<TimerNodeProps>
export const TimerNodeFactory: RecordFactory<TimerNodeProps> = Immutable.Record(
  ({
    id: '',
    type: 'TIMER',
    mode: 'for',
    timerReference: '',
    waitUntilTime: WaitUntilTimeFactory(),
    timer: AgeFactory({
      inputValue: '1',
      seconds: 3600,
      valid: true,
      unit: 'h',
      label: 'hour',
      fullText: '1 hour',
    }),
    errors: Immutable.Set(),
    nextNodeId: '',
    onEvents: new Immutable.List(),
  }: TimerNodeProps)
)

export type YesNoNodeProps = {
  id: string,
  type: 'YESNO',
  label: string,
  yesNodeId: string,
  noNodeId: string,
  errors: Set<NodeError>,
  ...
}
export type YesNoNodeRecord = RecordOf<YesNoNodeProps>
export const YesNoNodeFactory: RecordFactory<YesNoNodeProps> = Immutable.Record(
  ({
    id: '',
    type: 'YESNO',
    label: '',
    yesNodeId: '',
    noNodeId: '',
    errors: Immutable.Set(),
  }: YesNoNodeProps)
)

export type SplitBranchProps = {
  weight: number,
  nextNodeId: string,
}
export type SplitBranchRecord = RecordOf<SplitBranchProps>
export const SplitBranchFactory: RecordFactory<SplitBranchProps> = Immutable.Record(
  ({
    weight: 0,
    nextNodeId: '',
  }: SplitBranchProps)
)
export type RandomNodeProps = {
  id: string,
  type: 'RANDOM',
  label: string,
  splits: List<SplitBranchRecord>,
  errors: Set<NodeError>,
  ...
}
export type RandomNodeRecord = RecordOf<RandomNodeProps>
export const RandomNodeFactory: RecordFactory<RandomNodeProps> = Immutable.Record(
  ({
    id: '',
    label: '',
    type: 'RANDOM',
    errors: Immutable.Set(),
    splits: new Immutable.List(),
  }: RandomNodeProps)
)

export type JourneyNodeRecord =
  | FinalNodeRecord
  | MessageNodeRecord
  | TimerNodeRecord
  | YesNoNodeRecord
  | RandomNodeRecord

export const JourneyNodes = {
  Final: FinalNodeFactory,
  Message: MessageNodeFactory,
  Timer: TimerNodeFactory,
  YesNo: YesNoNodeFactory,
  Random: RandomNodeFactory,
}
export type NodesMap = Immutable.Map<string, JourneyNodeRecord>

// targetNode information
export type BranchId =
  | {
      type: 'ROOT',
      ...
    }
  | {
      type: 'MESSAGE',
      messageNodeId: string,
      ...
    }
  | {
      type: 'TIMER-NEXT',
      timerNodeId: string,
      ...
    }
  | {
      type: 'TIMER-EVENT',
      timerNodeId: string,
      triggerIndex: number,
      ...
    }
  | {
      type: 'YESNO',
      yesNoNodeId: string,
      branch: 'yes' | 'no',
      ...
    }
  | {
      type: 'RANDOM',
      randomNodeId: string,
      splitIndex: number,
      ...
    }
  | {
      type: 'REJOIN',
      nodeId: string,
      ...
    }
