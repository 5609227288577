// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import {
  quietTimesSelector,
  journeyTreeSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { QuietTimesSummary } from 'com.batch/orchestration-journey/ui/components/quiet-time-summary'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

type MessageQuietTimesProps = {
  channel: ChannelUntilCleanup,
  stepId?: ?string,
  ...
}

export const MessageQuietTimes = ({ channel, stepId }: MessageQuietTimesProps): React.Node => {
  const dispatch = useDispatch()
  const { hasQuietTimes, quietTimes } = useSelector(quietTimesSelector)
  const { nodesMap } = useSelector(journeyTreeSelector)
  const node = React.useMemo(() => (stepId ? nodesMap.get(stepId) : null), [nodesMap, stepId])

  const onChangeQuietTimesEnabledForNode = React.useCallback(
    (quietTimesForNode: boolean) => {
      if (node?.type === 'MESSAGE') {
        dispatch(updateNode(node.set('hasQuietTimes', quietTimesForNode)))
      }
    },
    [dispatch, node]
  )

  const onUpdateNodeHasQuietTimes = React.useCallback(
    (bool: boolean) => {
      if (onChangeQuietTimesEnabledForNode) onChangeQuietTimesEnabledForNode(bool)
    },
    [onChangeQuietTimesEnabledForNode]
  )

  return hasQuietTimes ? (
    <div
      style={
        channel === 'email' ? { borderLeft: `1px solid ${colors.stroke}`, paddingLeft: 28 } : {}
      }
    >
      <Switch
        isActive={!!(node?.type === 'MESSAGE' && node.hasQuietTimes)}
        onChange={onUpdateNodeHasQuietTimes}
      >
        Quiet times <Icon style={{ marginLeft: 8 }} icon="quiet-hours" />
      </Switch>
      {!!(node?.type === 'MESSAGE' && node.hasQuietTimes) && (
        <p style={{ marginTop: 8 }}>
          <QuietTimesSummary quietTimes={quietTimes} />
        </p>
      )}
      {!!(node?.type === 'MESSAGE' && !node.hasQuietTimes) && (
        <p style={{ marginTop: 8 }}>Users will receive messages at any time.</p>
      )}
      {channel === 'push' && <HorizontalDivider style={{ margin: '20px 0' }} />}
    </div>
  ) : null
}
