// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { Handle, Position, useUpdateNodeInternals } from 'reactflow'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { RandomButton, type RandomButtonProps } from './nodes/random-button'
import { YesNoButton, type YesNoButtonProps } from './nodes/yesno-button'

import {
  copiedNodeIdSelector,
  getEditingNodeId,
  getNodeHasTargetingSelector,
  journeySettingsSelector,
} from '../../models/journey.selectors'
import { JourneyEnd } from '../pages/journey-canvas-form.styles'
import { messageStateSelector } from 'com.batch/message/store/message.selector'

import {
  EmailContentFactory,
  PushMessageRawFactory,
} from 'com.batch/message/models/message.records'
import {
  EventButton,
  type EventButtonProps,
} from 'com.batch/orchestration-journey/ui/components/nodes/event-button'
import {
  JourneyEntryBlock,
  type JourneyEntryBlockProps,
} from 'com.batch/orchestration-journey/ui/components/nodes/journey-entry-block'
import {
  LineButton,
  type LineButtonProps,
} from 'com.batch/orchestration-journey/ui/components/nodes/line-button'
import {
  MessageBlock,
  type MessageBlockProps,
} from 'com.batch/orchestration-journey/ui/components/nodes/message-block'
import {
  TimerButton,
  type TimerButtonProps,
} from 'com.batch/orchestration-journey/ui/components/nodes/timer-button'

const NodeContainer = styled.div`
  padding: 10px 0;
  position: relative;
  z-index: 0;
`

type NodeProps<T, D> = {
  id: string,
  type: T,
  data: {
    ...D,
    reduxNodeId: string,
  },
}
type EntryNodeProps = NodeProps<'entryNode', JourneyEntryBlockProps>
export const EntryNode = ({ data }: EntryNodeProps): React.Node => {
  const getHasCustomTargeting = useSelector(getNodeHasTargetingSelector)
  const hasCustomTargeting = getHasCustomTargeting('default')
  const settings = useSelector(journeySettingsSelector)
  const editingNodeId = useSelector(getEditingNodeId)
  return (
    <React.Fragment>
      <JourneyEntryBlock
        {...data}
        hasCustomTiming={settings.hasStart}
        hasCustomTargeting={hasCustomTargeting}
        hasQuietTimes={settings.hasQuietTimes}
        editingNodeId={editingNodeId}
      />

      <Handle type="source" position={Position.Bottom} id="next" />
    </React.Fragment>
  )
}
type EventNodeProps = NodeProps<'eventNode', { ...EventButtonProps, stickToEdge?: boolean }>
const stickToEdgeStyle = {
  margin: '-4px 0',
}
export const EventNode = (props: EventNodeProps): React.Node => {
  const editingNodeId = useSelector(getEditingNodeId)
  const { stickToEdge, ...data } = props.data
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="target" />
      <div style={stickToEdge ? stickToEdgeStyle : null}>
        <EventButton {...data} editingNodeId={editingNodeId} />
        <span className="styled-journey-debug" style={{ marginTop: 20 }}>
          {data.reduxNodeId}
        </span>
      </div>
      <Handle type="source" position={Position.Bottom} id="next" />
    </React.Fragment>
  )
}
type MessageNodeProps = NodeProps<'messageNode', MessageBlockProps>
export const MessageNode = ({ data }: MessageNodeProps): React.Node => {
  const editingNodeId = useSelector(getEditingNodeId)
  const copiedNodeId = useSelector(copiedNodeIdSelector)
  const settings = useSelector(journeySettingsSelector)
  const messageState = useSelector(messageStateSelector)
  const content: string = React.useMemo(() => {
    const emailMessageContent =
      messageState.email.getIn([data.messageReference, 'default'], EmailContentFactory()).html ?? ''
    const smsMessage = messageState.sms.getIn([data.messageReference, 'default', 'smsMessage'], '')
    return data.channel === 'email' ? emailMessageContent : smsMessage
  }, [data.channel, data.messageReference, messageState.email, messageState.sms])
  const pushMessageContent = React.useMemo(
    () =>
      messageState.push.getIn(
        [data.messageReference, 'default', 'content'],
        PushMessageRawFactory()
      ),
    [data.messageReference, messageState.push]
  )

  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="target" />
      <NodeContainer className="nopan nodrag">
        <span className="styled-journey-debug">{data.reduxNodeId}</span>

        <MessageBlock
          {...data}
          errors={data.errors}
          hasGlobalQuietTimes={settings.hasQuietTimes}
          content={data.channel === 'email' || data.channel === 'sms' ? content : ''}
          pushContent={data.channel === 'push' ? pushMessageContent : undefined}
          editingNodeId={copiedNodeId === data.reduxNodeId ? copiedNodeId : editingNodeId}
        />
      </NodeContainer>
      <Handle type="source" position={Position.Bottom} id="next" />
    </React.Fragment>
  )
}
type ButtonNodeProps = NodeProps<'buttonNode', LineButtonProps>
export const ButtonNode = ({ data }: ButtonNodeProps): React.Node => {
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="enter" />
      <span className="styled-journey-debug">{data.reduxNodeId}</span>
      <div style={stickToEdgeStyle}>
        <LineButton {...data} className="nopan nodrag" />
      </div>
      <Handle type="source" position={Position.Bottom} id="next" />
    </React.Fragment>
  )
}
type TimerNodeProps = NodeProps<'timerNode', { ...TimerButtonProps, stickToEdge?: boolean }>
export const TimerNode = (props: TimerNodeProps): React.Node => {
  const editingNodeId = useSelector(getEditingNodeId)
  const { stickToEdge, ...data } = props.data
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="enter" />
      <Tooltip
        placement="left-end"
        offset={[0, 5]}
        minWidth={210}
        isTooltipEmpty={!data.errors.includes('INVALID_END')}
        tooltip="Impossible to end an automation by a delay step, add a message or delete this step."
      >
        <div style={stickToEdge ? stickToEdgeStyle : null}>
          <TimerButton {...data} className="nopan nodrag" editingNodeId={editingNodeId} />
          <span className="styled-journey-debug" style={{ marginTop: 20 }}>
            {data.reduxNodeId}
          </span>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Bottom} id="next" />
    </React.Fragment>
  )
}
type ExitNodeProps = NodeProps<'exitNode', { reduxNodeId: string }>
export const ExitNode = ({ data }: ExitNodeProps): React.Node => {
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Left} id="enter-left" />
      <Handle type="target" position={Position.Top} id="enter-top" />
      <NodeContainer className="nopan nodrag">
        <span className="styled-journey-debug">{data.reduxNodeId}</span>
        <JourneyEnd className="nopan nodrag">
          <Icon icon="exit" />
          Exit
        </JourneyEnd>
      </NodeContainer>
    </React.Fragment>
  )
}

type YesNoNodeProps = NodeProps<'yesnoNode', { ...YesNoButtonProps }>
export const YesNoNode = ({ data }: YesNoNodeProps): React.Node => {
  const editingNodeId = useSelector(getEditingNodeId)
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="enter" style={{ top: -10 }} />
      <span className="styled-journey-debug" style={{ marginTop: 20 }}>
        {data.reduxNodeId}
      </span>
      <YesNoButton {...data} editingNodeId={editingNodeId} />
      <Handle type="source" id="yes" style={{ left: 43, top: 86 }} position={Position.Bottom} />
      <Handle type="source" id="no" style={{ left: 237, top: 86 }} position={Position.Bottom} />
    </React.Fragment>
  )
}

type RandomNodeProps = NodeProps<'randomNode', { ...RandomButtonProps }>
export const RandomNode = ({ data, id }: RandomNodeProps): React.Node => {
  const editingNodeId = useSelector(getEditingNodeId)
  const updateNodeInternals = useUpdateNodeInternals()
  React.useEffect(() => {
    updateNodeInternals(id)
  }, [data.randomNode.splits.size, id, updateNodeInternals])
  const widthPerSplit = 380 / data.randomNode.splits.size
  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} id="enter" style={{ top: -10 }} />
      <span className="styled-journey-debug" style={{ marginTop: 20 }}>
        {data.reduxNodeId}
      </span>
      <RandomButton {...data} editingNodeId={editingNodeId} />
      {data.randomNode.splits.map((_, index) => {
        const isFirst = index === 0
        const isLast = index === data.randomNode.splits.size - 1
        const allBranchesBottom = data.randomNode.splits.size === 2
        return (
          <Handle
            key={index}
            type="source"
            id={index.toString()}
            style={{
              left:
                index * widthPerSplit +
                widthPerSplit / 2 -
                50 +
                (allBranchesBottom ? 0 : isFirst ? -28 : isLast ? 22 : 0),
              top: !allBranchesBottom && (isFirst || isLast) ? 73 : 86,
            }}
            position={
              allBranchesBottom
                ? Position.Bottom
                : isFirst
                  ? Position.Left
                  : isLast
                    ? Position.Right
                    : Position.Bottom
            }
          />
        )
      })}
    </React.Fragment>
  )
}

export const nodeTypes = {
  entryNode: EntryNode,
  messageNode: MessageNode,
  yesnoNode: YesNoNode,
  randomNode: RandomNode,
  timerNode: TimerNode,
  exitNode: ExitNode,
  eventNode: EventNode,
  buttonNode: ButtonNode,
}

export type HandledNodesType =
  | EntryNodeProps
  | MessageNodeProps
  | ExitNodeProps
  | TimerNodeProps
  | EventNodeProps
  | RandomNodeProps
  | ButtonNodeProps
  | YesNoNodeProps
