// @flow
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { SidebarConnected } from 'components/app/nav/sidebar-connected'
import { NavbarConnected } from 'components/project/navigation/navbar-connected'

const companySettingsRegex = /^\/?(\d*)\/(settings|billing|gdpr|users)(.*)/
export const NavInserter = (): React.Node => {
  const { pathname } = useLocation()
  const sidebarKind = React.useMemo(() => {
    if (
      pathname.includes('register') ||
      pathname.includes('login') ||
      pathname.includes('password')
    )
      return 'none'
    if (
      pathname.includes('/account/') ||
      pathname.endsWith('/account') ||
      pathname.match(companySettingsRegex)
    )
      return 'account'
    return 'dashboard'
  }, [pathname])

  return sidebarKind === 'none' ? null : (
    <React.Fragment>
      {sidebarKind === 'dashboard' && <NavbarConnected />}
      <SidebarConnected kind={sidebarKind} />
    </React.Fragment>
  )
}
