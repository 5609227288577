// @flow

import { type Map, type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useFetchAppFromRouter } from 'components/_hooks'
import { CreateAppRoute } from 'components/app/create/create-app-route'
import Integrate from 'components/app/integrate/integrate'
import { Wrapper, UserbaseEmptyIcon, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import Hint from 'components/common/hint'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'
import { Tooltip } from 'components/common/tooltip'
import { Content } from 'components/styled/blocs'
import { Title } from 'components/styled/text'

import { numberFormat, percentage } from 'com.batch.common/utils'

import { ClusterBlock as Cluster } from './cluster'
import { UserbaseAttributes } from './userbase-attributes'
import { UserbaseHeader, ClusterLine, LegendedNumber } from './userbase.styles'

import {
  type ClusterStateRecord,
  type ClusterRatioRecord,
  type AttributeRecord,
  type AttributeValuesListRecord,
  type State,
  AttributeFactory,
  AttributeValuesListFactory,
} from 'com.batch.redux/_records'
import {
  userbaseAttrSelector,
  allAttrFilteredValuesSelector,
} from 'com.batch.redux/attribute.selector'
import { fetchUserbase, ratioMode, toggleAliveDisplay } from 'com.batch.redux/cluster'
import { devApiKeyFeatureSelector } from 'com.batch.redux/company.selector'
import { loadingOverviewSelector } from 'com.batch.redux/stat.selector.analytics'

import { STATUS } from 'constants/common'

const attrConfigSelector = (state: State) => state.attribute.config
const UserbaseRaw = () => {
  const dispatch = useDispatch()

  // ====================== REDUX STATES DATAS
  // clusters
  const clusters: ClusterStateRecord = useSelector(state => state.cluster)
  const ratio: ClusterRatioRecord = useSelector(state => ratioMode(state))
  const mode: 'all' | 'alive' = useSelector(state => (state.cluster.showAlive ? 'alive' : 'all'))
  const app = useFetchAppFromRouter()
  // attributes
  const attributesConfig = useSelector(attrConfigSelector)
  const overviewStatus = useSelector(loadingOverviewSelector)

  const hasDevApiKeyFeature = useSelector(devApiKeyFeatureSelector)

  const attributes: List<AttributeRecord> = useSelector(userbaseAttrSelector)
  const attributesValues: Map<string, AttributeValuesListRecord> = useSelector(
    allAttrFilteredValuesSelector
  )
  const firstAttribute: AttributeRecord = attributes.get(0, AttributeFactory())
  const attibutesValuesLoading: boolean = attributesValues.get(
    firstAttribute.id,
    AttributeValuesListFactory()
  ).loading

  // ====================== COMPONENT CONSTANTS
  // loading
  const loadingClusters: boolean =
    clusters.status === STATUS.INIT || clusters.status === STATUS.LOADING
  const loadingAttributes: boolean =
    overviewStatus === STATUS.INIT || overviewStatus === STATUS.LOADING || attibutesValuesLoading
  const loading: boolean = loadingClusters || loadingAttributes

  // failure
  const errorClusters: boolean = clusters.status === STATUS.ERROR
  const errorAttributes: boolean =
    attributesConfig.attributeLoadingState === 'ERROR' || overviewStatus === STATUS.ERROR
  const errors: boolean = errorClusters && errorAttributes

  // empty
  const isEmpty: boolean =
    (clusters.get(mode).installs === 0 || clusters.get(mode).installs === null) &&
    clusters.status === STATUS.LOADED

  // ====================== USE EFFECT
  React.useEffect(() => {
    if (app?.id) dispatch(fetchUserbase(app?.id))
    document.addEventListener('keyup', toggleAlive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id, dispatch])

  const optinRate = clusters.get(mode).get(ratio.mode) || 0

  const toggleAlive = (event: KeyboardEvent) => {
    if (event.keyCode === 72) {
      dispatch(toggleAliveDisplay())
    }
  }
  // ====================== RENDER
  return (
    <React.Fragment>
      <PageTracker category={trackingCategories.data} name={trackingPages.userbase}>
        <Content>
          <Wrapper
            isEmpty={isEmpty || errors}
            isLoading={loadingClusters}
            isOverlayShown={!loadingClusters && (isEmpty || errors)}
            overlayProps={
              errors
                ? GlobalErrorOverlayProps
                : {
                    status: 'empty-page',
                    title: 'No data to display',
                    description:
                      'We haven’t been able to find any data yet. Please check if your SDK is up and running to fill your userbase.',
                    content: <UserbaseEmptyIcon />,
                    links: [
                      {
                        name: 'Troubleshooting',
                        href: 'https://doc.batch.com/dashboard/analytics/troubleshooting',
                      },
                      { name: 'Install the SDK', href: 'https://doc.batch.com/download/' },
                    ],
                  }
            }
          >
            <Grid
              template={mode === 'alive' ? '1fr auto auto' : '1fr auto'}
              alignItems="center"
              margin={[0, 0, 23, 0]}
            >
              <Title mb={0} style={{ zIndex: 12 }}>
                Userbase
              </Title>

              {mode === 'alive' && (
                <p className="text-muted">Only users who launched the app in the last 6 months</p>
              )}

              <UserbaseHeader>
                <Tooltip
                  tooltip="Total number of app installs since you released it with Batch SDK."
                  minWidth={200}
                >
                  <span>
                    <LegendedNumber legend="Installs">
                      {numberFormat(clusters.get(mode).installs || 0)}
                    </LegendedNumber>
                  </span>
                </Tooltip>

                <LegendedNumber
                  legend={
                    <span>
                      Opt-ins {percentage(optinRate, optinRate < 0.01 && optinRate > 0 ? 1 : 0)}
                      <Hint style={{ marginRight: -5 }}>{ratio.desc}</Hint>
                    </span>
                  }
                >
                  <Tooltip
                    tooltip={`Out of ${numberFormat(clusters.get(mode).tokens || 0)} tokens`}
                  >
                    <span>{numberFormat(clusters.get(mode).notifsOn || 0)}</span>
                  </Tooltip>
                </LegendedNumber>
              </UserbaseHeader>
            </Grid>

            <ClusterLine
              template="minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)"
              gap={18}
            >
              <Cluster cluster={clusters.N} sub={clusters.Np} mode={mode} algo={ratio.mode} />
              <Cluster cluster={clusters.E} sub={clusters.Er} mode={mode} algo={ratio.mode} />
              <Cluster cluster={clusters.D} sub={clusters.Dp} mode={mode} algo={ratio.mode} />
              <div>
                <Cluster cluster={clusters.Du} mode={mode} algo={ratio.mode} />
                <Cluster
                  cluster={clusters.I}
                  tokensOnly
                  mode={mode}
                  algo={ratio.mode}
                  style={{ height: 106 }}
                />
              </div>
            </ClusterLine>

            {app ? (
              <UserbaseAttributes
                app={app}
                attributes={attributes}
                values={attributesValues}
                loading={loadingAttributes || (errorAttributes && loadingClusters)}
                failure={errorAttributes && !errors && !loading}
                hasDevApiKeyFeature={hasDevApiKeyFeature}
                attributeLoadingState={attributesConfig.attributeLoadingState}
              />
            ) : null}
          </Wrapper>
        </Content>
      </PageTracker>
      <CreateAppRoute />
      <Integrate />
    </React.Fragment>
  )
}

export const Userbase: React.AbstractComponent<{}> = React.memo<{}>(UserbaseRaw)
