// @flow

import { useReducedMotion } from '@react-spring/web'
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactFlow, { applyEdgeChanges, applyNodeChanges, Background } from 'reactflow'
import { ThemeProvider } from 'styled-components'

import { useToggle, useWindowSize, useIsCurrentUserAllowedTo } from 'components/_hooks'

import { ReactFlowContainer } from './journey-canvas-form.styles'

import {
  type JourneyNodeRecord,
  type BranchId,
  type MessageNodeRecord,
  type NodeType,
  type RandomNodeRecord,
  RandomNodeFactory,
  MessageNodeFactory,
} from '../../models/journey.records'
import {
  copiedNodeIdSelector,
  getEditingNodeId,
  journeySettingsSelector,
  journeyTreeSelector,
} from '../../models/journey.selectors'
import { checkIncompleteMessageNode } from '../../usecases/check-incomplete-message-node'
import { checkIncompleteYesNoNode } from '../../usecases/check-missing-targeting-yesno-node'
import { copyMessageNode } from '../../usecases/copy-message-node'
import { pasteMessageNode } from '../../usecases/paste-message-node'
import { CancelPastePill } from '../components/cancel-paste-pill'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { FooterOrchestration } from 'com.batch/orchestration/ui/components/footer-orchestration'
import { useForceEnabledForm } from 'com.batch/orchestration/ui/components/use-force-enabled-form'
import { fetchOrchestrationStatsByStep } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-steps'
import { buildNodesAndEdges } from 'com.batch/orchestration-journey/helpers/reactflow.formatter'
import { type InterractionCallbacks } from 'com.batch/orchestration-journey/helpers/reactflow.types'
import { SheetMessage } from 'com.batch/orchestration-journey/ui/components/canvas/sheet-message'
import { SheetRandom } from 'com.batch/orchestration-journey/ui/components/canvas/sheet-random'
import { SheetYesNo } from 'com.batch/orchestration-journey/ui/components/canvas/sheet-yesno'
import { TimerSettingsSheet } from 'com.batch/orchestration-journey/ui/components/canvas/timer-settings-sheet'
import { Controls } from 'com.batch/orchestration-journey/ui/components/controls/controls'
import { CustomEdgeArrow } from 'com.batch/orchestration-journey/ui/components/custom-edge-arrow'
import { edgeTypes } from 'com.batch/orchestration-journey/ui/components/edges'
import { nodeTypes } from 'com.batch/orchestration-journey/ui/components/nodes'
import { RemoveNodeModal } from 'com.batch/orchestration-journey/ui/components/remove-node-modal'
import { SheetSettings } from 'com.batch/orchestration-journey/ui/components/sheet-settings'
import { insertNodeAfter } from 'com.batch/orchestration-journey/usecases/insert-node-after'
import { setEditingNodeId } from 'com.batch/orchestration-journey/usecases/set-editing-node-id'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

const proOptions = { hideAttribution: true }
export type sheetKind =
  | 'enter'
  | 'settings'
  | 'targeting'
  | 'message'
  | 'none'
  | 'timer'
  | 'yesno'
  | 'random'
export type settingsTabKind = 'enter' | 'quiet-hours' | 'timings' | 'targeting'
/*
  Renders form head & footer, and the react-flow tree :
    - uses reactflow.formatter to convert redux tree to rf tree 
      (we need nodes & edges, and redux timer node = 1 or 3 rf nodes)
    - backup restore functionnality for the sheets :
      - journey entry & timer nodes work on a temporary state (triggerConfig), 
        which is sent to redux on commit
      - targeting, query & message work with a useBackup hook that save a 
        copy of redux state on open, works directy on the redux state, and restore
        backup on dismiss

*/

export const JourneyCanvasForm: React.AbstractComponent<{ ... }> = React.memo((): React.Node => {
  const reducedMotion = useReducedMotion()
  const ZOOM_DURATION = reducedMotion ? 0 : 280
  // ------------ redux state ------------
  const dispatch = useDispatch()
  const journeySettings = useSelector(journeySettingsSelector)
  const editingNodeId = useSelector(getEditingNodeId)
  const copiedNodeId = useSelector(copiedNodeIdSelector)
  const [headerHeight, setHeaderHeight] = React.useState(194)
  const { rootNodeId, nodesMap } = useSelector(journeyTreeSelector)
  const project = useSelector(currentProjectSelector)
  const [rejoinNodeId, setRejoinNodeId] = React.useState('')
  const { state, id } = useSelector(orchestrationMetaSelector)
  // ------------ local state -----------
  const statViewState = useToggle()
  const onKeyDown = React.useCallback(
    evt => {
      if ((evt.key === 's' || evt.key === 'S') && evt.ctrlKey) {
        evt.preventDefault()
        statViewState.toggle()
        dispatch(fetchOrchestrationStatsByStep({ token: id }))
      }
    },
    [dispatch, id, statViewState]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
  const lastShownMessageNode = React.useRef<MessageNodeRecord>(MessageNodeFactory())
  const [paneWidth, setPaneWidth] = React.useState(0)
  const forceEnabled = useForceEnabledForm()
  const debugState = useToggle()
  const availableChannels = React.useMemo(() => {
    const channels = []
    if (project.emailConfigured) {
      channels.push('email')
    }
    if (project.smsConfigured) {
      channels.push('sms')
    }
    if (project.pushConfigured) {
      channels.push('push')
    }
    return Immutable.Set(channels)
  }, [project.emailConfigured, project.smsConfigured, project.pushConfigured])
  const [translateExtent, setTranslateExtent] = React.useState<
    [[number, number], [number, number]],
  >([
    [-Infinity, -Infinity],
    [Infinity, Infinity],
  ])

  const [settingsTab, setSettingsTab] = React.useState<settingsTabKind>('enter')
  const lastRequestToMove = React.useRef({ centerX: 0, centerY: 0, zoom: 0, windowWidth: 0 })
  const [deleteNodeId, setDeleteNodeId] = React.useState('')
  const closeDeleteNodeModal = React.useCallback(() => setDeleteNodeId(''), [])
  const {
    currentNode,
    isSettingsOpened,
  }: { currentNode: ?JourneyNodeRecord, isSettingsOpened: boolean, ... } = React.useMemo(() => {
    if (editingNodeId === '')
      return {
        currentNode: null,
        isSettingsOpened: false,
      }
    if (editingNodeId === 'ROOT')
      return {
        currentNode: null,
        isSettingsOpened: true,
      }
    const node = nodesMap.get(editingNodeId)
    if (!node) {
      return {
        currentNode: null,
        isSettingsOpened: false,
      }
    }
    switch (node.type) {
      case 'MESSAGE':
      case 'TIMER':
      case 'YESNO':
      case 'RANDOM':
        return {
          currentNode: node,
          isSettingsOpened: false,
        }
      default:
        return {
          currentNode: null,
          isSettingsOpened: false,
        }
    }
  }, [editingNodeId, nodesMap])

  const coerceCurrentNodeToRandomNode = React.useMemo(() => {
    if (currentNode?.type === 'RANDOM') {
      return currentNode
    }
    return RandomNodeFactory()
  }, [currentNode])

  const coerceCurrentNodeToMessageNode = React.useMemo(() => {
    if (currentNode?.type === 'MESSAGE') {
      return currentNode
    }
    return lastShownMessageNode.current
  }, [currentNode])
  // ------------ callbacks --------------

  const reactFlow = React.useRef(null)

  const shortcutListner = React.useCallback(
    e => {
      if (e.key === 'd' && e.ctrlKey) {
        debugState.toggle()
      }
    },
    [debugState]
  )

  const onInsertNode = React.useCallback(
    ({
      branchIds,
      nodeType,
      channel,
    }: {
      nodeType: NodeType,
      branchIds: Array<BranchId>,
      channel: ?ChannelUntilCleanup,
      ...
    }) => {
      dispatch(insertNodeAfter({ branchIds, nodeType, channel }))
    },
    [dispatch]
  )
  const removeCancelEvents = React.useCallback(
    (nodeId: string) => {
      const timer = nodesMap.get(nodeId)
      if (timer && timer.type === 'TIMER') {
        dispatch(updateNode(timer.set('onEvents', new Immutable.List())))
      }
    },
    [dispatch, nodesMap]
  )
  const openTimerSheet = React.useCallback(
    (nodeId: string) => {
      const node = nodesMap.get(nodeId)

      if (!node || node.type !== 'TIMER') {
        throw new Error(`Timer node ${nodeId} does not exist`)
      }
      dispatch(setEditingNodeId(nodeId))
    },
    [nodesMap, dispatch]
  )

  const openSettingsSheet = React.useCallback(
    (tab: settingsTabKind) => {
      setSettingsTab(tab)
      dispatch(setEditingNodeId('ROOT'))
    },
    [dispatch]
  )

  const openYesNoSheet = React.useCallback(
    (nodeId: string) => {
      dispatch(setEditingNodeId(nodeId))
    },
    [dispatch]
  )

  const openMessageSheet = React.useCallback(
    (msgNode: MessageNodeRecord) => {
      dispatch(setEditingNodeId(msgNode.id))
      lastShownMessageNode.current = msgNode
    },
    [dispatch]
  )
  const openRandomSheet = React.useCallback(
    (node: RandomNodeRecord, rejoinNodeId: string) => {
      setRejoinNodeId(rejoinNodeId)
      dispatch(setEditingNodeId(node.id))
    },
    [dispatch]
  )
  const onCancelCopy = React.useCallback(() => {
    dispatch(copyMessageNode(''))
  }, [dispatch])
  const onPasteNode = React.useMemo(() => {
    return (branchIds: Array<BranchId>) => {
      dispatch(
        pasteMessageNode({
          branchIds,
        })
      )
    }
  }, [dispatch])

  const onRandomNodeChange = React.useCallback(
    (node: RandomNodeRecord) => {
      dispatch(updateNode(node))
    },
    [dispatch]
  )
  // ------------ getting canvas state ---
  const [nodes, setNodes] = React.useState([])
  const [edges, setEdges] = React.useState([])
  const callbacksForReactFlow: InterractionCallbacks = React.useMemo(() => {
    return {
      onInsertNode,
      openMessageSheet,
      openSettingsSheet,
      removeCancelEvents,
      openRandomSheet,
      onPasteNode,
      onRemoveNode: setDeleteNodeId,
      openTimerSheet,
      openYesNoSheet,
    }
  }, [
    onInsertNode,
    openMessageSheet,
    openSettingsSheet,
    removeCancelEvents,
    openRandomSheet,
    onPasteNode,
    openTimerSheet,
    openYesNoSheet,
  ])
  const onNodesChange = React.useCallback(
    changes => setNodes(nds => applyNodeChanges(changes, nds)),
    [setNodes]
  )
  const onEdgesChange = React.useCallback(
    changes => setEdges(eds => applyEdgeChanges(changes, eds)),
    [setEdges]
  )
  // cleanup active sheet on nav out
  React.useEffect(() => {
    return () => {
      dispatch(setEditingNodeId(''))
    }
  }, [dispatch])
  React.useEffect(() => {
    const { reactflowNodes, reactflowEdges } = buildNodesAndEdges({
      settings: journeySettings,
      rootNodeId,
      availableChannels,
      nodes: nodesMap,
      callbacks: callbacksForReactFlow,
    })
    setNodes(reactflowNodes)
    setEdges(reactflowEdges)
    const extent = [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ]
    reactflowNodes.forEach(node => {
      if (node.position.x < extent[0][0]) {
        extent[0][0] = node.position.x
      }
      if (node.position.y < extent[0][1]) {
        extent[0][1] = node.position.y
      }
      if (node.position.x > extent[1][0]) {
        extent[1][0] = node.position.x
      }
      if (node.position.y > extent[1][1]) {
        extent[1][1] = node.position.y
      }
    })
    const PAD = 700
    setTranslateExtent([
      [extent[0][0] - 2 * PAD, extent[0][1] - PAD],
      [extent[1][0] + 2 * PAD, extent[1][1] + PAD],
    ])
  }, [
    callbacksForReactFlow,
    journeySettings,
    nodesMap,
    rootNodeId,
    setEdges,
    setNodes,
    availableChannels,
  ])

  // keydown listner for debug mode
  React.useEffect(() => {
    const container = document.getElementById('reactflow-container')
    if (container) {
      setHeaderHeight(container.getBoundingClientRect().top)
    }
    window.addEventListener('keydown', shortcutListner)
    return () => window.removeEventListener('keydown', shortcutListner)
  }, [shortcutListner])
  const windowSize = useWindowSize()
  const refocusOnNode = React.useMemo(
    () =>
      ({ width = 0, zoom = 1 }: { width?: number, zoom?: number, ... }) => {
        setPaneWidth(width)
        const lastWidth = width ? width : paneWidth
        const isScreenWideEnough = (windowSize.width ?? 0) > lastWidth + 300
        if (isScreenWideEnough) {
          const nodeToZoom = nodes.find(({ id }) => id === editingNodeId)
          const centerX = nodeToZoom?.position?.x ? nodeToZoom.position.x - width / 2 : NaN
          const centerY = nodeToZoom?.position?.y ? nodeToZoom.position.y + 150 : NaN
          if (nodeToZoom && !isNaN(centerX) && !isNaN(centerY)) {
            if (
              zoom !== lastRequestToMove.current?.zoom ||
              centerX !== lastRequestToMove.current?.centerX ||
              centerY !== lastRequestToMove.current?.centerY ||
              windowSize.width !== lastRequestToMove.current?.windowWidth
            ) {
              lastRequestToMove.current = {
                centerX,
                centerY,
                windowWidth: windowSize.width,
                zoom,
              }
              reactFlow.current?.setCenter(centerX, centerY, {
                duration: ZOOM_DURATION,
                zoom,
              })
            }
          }
        }
      },
    [nodes, editingNodeId, windowSize.width, paneWidth, ZOOM_DURATION]
  )
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const formTheme = React.useMemo(
    () => ({
      kind: 'capture',
      viewStats: statViewState.value,
      disabledMode: (state === 'COMPLETED' || !userHasWritePermission) && !forceEnabled,
    }),
    [statViewState.value, state, userHasWritePermission, forceEnabled]
  )
  const restoreZoom = React.useMemo(
    () => (editingNodeId ? () => refocusOnNode({}) : undefined),
    [refocusOnNode, editingNodeId]
  )
  const reactFlowOnInit = React.useCallback(reactFlowInstance => {
    reactFlow.current = reactFlowInstance
  }, [])

  const closeSheet = React.useCallback(() => {
    if (currentNode && currentNode.type === 'YESNO') {
      dispatch(checkIncompleteYesNoNode(currentNode.id))
    }
    if (currentNode?.type === 'MESSAGE') {
      dispatch(checkIncompleteMessageNode(currentNode))
    }
    dispatch(setEditingNodeId(''))
    refocusOnNode({ zoom: 1 })
  }, [currentNode, dispatch, refocusOnNode])
  const onClickBackground = React.useMemo(() => {
    if (editingNodeId) {
      return e => {
        if (e.target.classList.contains('react-flow__pane')) {
          closeSheet()
        }
      }
    }
  }, [closeSheet, editingNodeId])

  // ======== RENDER ==================================
  return (
    <div style={{ display: 'flex' }}>
      <ThemeProvider theme={formTheme}>
        {deleteNodeId && (
          <RemoveNodeModal
            nodeId={deleteNodeId}
            onClose={closeDeleteNodeModal}
            callbackAfterDelete={restoreZoom}
          />
        )}
        <CustomEdgeArrow />

        <SheetMessage
          isOpened={currentNode?.type === 'MESSAGE'}
          dismiss={closeSheet}
          node={coerceCurrentNodeToMessageNode}
          refocusOnNode={refocusOnNode}
        />

        <SheetSettings
          tab={settingsTab}
          setSettingsTab={setSettingsTab}
          isOpened={isSettingsOpened}
          dismiss={closeSheet}
          refocusOnNode={refocusOnNode}
        />
        <SheetYesNo
          isOpened={currentNode?.type === 'YESNO'}
          dismiss={closeSheet}
          id={editingNodeId}
          refocusOnNode={refocusOnNode}
        />
        <TimerSettingsSheet
          isOpened={currentNode?.type === 'TIMER'}
          dismiss={closeSheet}
          nodeId={editingNodeId}
          refocusOnNode={refocusOnNode}
        />
        <SheetRandom
          dismiss={closeSheet}
          rejoinNodeId={rejoinNodeId}
          isOpened={currentNode?.type === 'RANDOM'}
          onChange={onRandomNodeChange}
          value={coerceCurrentNodeToRandomNode}
          refocusOnNode={refocusOnNode}
        />

        {/* specific sheet to ysno */}
        <SheetSettings
          tab={settingsTab}
          setSettingsTab={setSettingsTab}
          isOpened={isSettingsOpened}
          dismiss={closeSheet}
          refocusOnNode={refocusOnNode}
        />
        <SheetYesNo
          isOpened={currentNode?.type === 'YESNO'}
          dismiss={closeSheet}
          id={editingNodeId}
          refocusOnNode={refocusOnNode}
        />
        <TimerSettingsSheet
          isOpened={currentNode?.type === 'TIMER'}
          dismiss={closeSheet}
          nodeId={editingNodeId}
          refocusOnNode={refocusOnNode}
        />
        <SheetRandom
          dismiss={closeSheet}
          rejoinNodeId={rejoinNodeId}
          isOpened={currentNode?.type === 'RANDOM'}
          onChange={onRandomNodeChange}
          value={coerceCurrentNodeToRandomNode}
          refocusOnNode={refocusOnNode}
        />

        <ReactFlowContainer
          debug={debugState.value}
          id="reactflow-container"
          $grabEnabled={!editingNodeId}
          $headerHeight={headerHeight}
          onClick={onClickBackground}
        >
          <ReactFlow
            onInit={reactFlowOnInit}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            preventScrolling={false}
            nodesConnectable={false}
            nodeTypes={nodeTypes}
            disableKeyboardA11y
            translateExtent={!editingNodeId ? translateExtent : undefined}
            edgeTypes={edgeTypes}
            proOptions={proOptions}
            defaultViewport={{ x: window.innerWidth / 2, y: 0, zoom: 1 }}
            elementsSelectable={true}
            deleteKeyCode={null}
            selectionKeyCode={null}
            multiSelectionKeyCode={null}
            zoomOnPinch={!editingNodeId}
            panOnScroll={true}
            nodeOrigin={[0.5, 0]}
            fitView={true}
            fitViewOptions={{
              minZoom: 0.7,
              maxZoom: 1,
            }}
            minZoom={0.2}
            maxZoom={1}
            zoomActivationKeyCode={['Meta', 'Control']}
          >
            <Background color="#E0E0E0" variant="dots" size={3} gap={30} />
            {!editingNodeId && <Controls />}
          </ReactFlow>
        </ReactFlowContainer>
        <CancelPastePill onCancel={onCancelCopy} shown={Boolean(copiedNodeId)} />
        <FooterOrchestration isFixed />
      </ThemeProvider>
    </div>
  )
})
