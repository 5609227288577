// @flow
import Immutable from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { buildAgeFromDuration } from 'com.batch.common/utils'

import { AgeFactory, TriggerConfigFactory } from 'com.batch.redux/_records'

import { type ListOrchestrationType } from 'com.batch/orchestration/infra/types/orchestration.types'
import {
  OneTimeFactory,
  type PartialOrchestrationRecord,
  PartialOrchestrationFactory,
  RecurrentFactory,
} from 'com.batch/orchestration-list/models/partial-orchestration.records'

/*
  ❌ attention : sur le listing (donc ici), les dates sont des timestamps (date string iso)
  sur le CRUD campaign et recurring, on a des dates au format 20231207110600
*/

export const parseOrchestrationToPartial = (
  orchestration: ListOrchestrationType
): PartialOrchestrationRecord => {
  return PartialOrchestrationFactory({
    name: orchestration.name,
    state: orchestration.orchestrationState ?? 'DRAFT',
    createdByApi: orchestration.orchestrationSource !== 'DASHBOARD',
    token: orchestration.id,
    channels: Immutable.Set(
      Array.isArray(orchestration.orchestrationChannels)
        ? orchestration.orchestrationChannels.map(osChannel =>
            osChannel === 'EMAIL' ? 'email' : osChannel === 'PUSH' ? 'push' : 'sms'
          )
        : []
    ),
    incomplete: orchestration.incomplete,
    sendType: orchestration.recurrent
      ? 'recurring'
      : orchestration.journey
        ? 'trigger'
        : 'scheduled',

    routingMode: orchestration.recurrent || orchestration.journey ? 'automations' : 'campaigns',
    oneTime: orchestration.onetime
      ? OneTimeFactory({
          sendDate: dayjs.utc(orchestration.onetime.sendTime),
          localTimezoneDependant: !orchestration.onetime?.utc,
        })
      : undefined,
    recurring: orchestration.recurrent
      ? RecurrentFactory({
          start: orchestration.recurrent.firstSendTime
            ? dayjs.utc(orchestration.recurrent.firstSendTime)
            : null,
          end: orchestration.recurrent?.endTime ? dayjs.utc(orchestration.recurrent.endTime) : null,
          localTimezoneDependant: !orchestration?.recurrent?.utc,
          repeatUnit: orchestration.recurrent?.recurrencePeriod,
          repeatFrequency: orchestration.recurrent?.recurrenceFrequency,
        })
      : undefined,
    triggerConfig: orchestration.journey
      ? TriggerConfigFactory({
          isHackForMultiStep: true,
          enterEvent: orchestration.journey.entryEvent,
          capping: orchestration.journey.capping ?? 0,
          hasCapping: !!orchestration.journey.capping,
          hasGrace: !!orchestration.journey.gracePeriod,
          hasExitEvent: false,
          grace: orchestration.journey.gracePeriod
            ? buildAgeFromDuration(orchestration.journey.gracePeriod, ['h', 'd'])
            : AgeFactory(),
        })
      : undefined,
  })
}
