// @flow
import Immutable, { type Set, type Map } from 'immutable'

import {
  type Blocks,
  type OrchestrationJourney,
  type OrchestrationJourneyInstanceId,
} from '../types/orchestration-journey.types'
import { type MessageStateRecord, type langId } from 'com.batch/message/store/message.state'
import { TargetStateFactory, type TargetStateRecord } from 'com.batch.redux/target/target.records'

import { type EmailContent } from 'com.batch/email/infra/types/email.message.types'
import {
  type PushContentRecord,
  PushContentFactory,
  SmsContentFactory,
  EmailContentFactory,
} from 'com.batch/message/models/message.records'
import {
  type JourneyNodeRecord,
  type JourneySettingsRecord,
  type QuietTimesRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import { formatPushMessage } from 'com.batch/push/infra/parses/push.format'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

type OrchestrationJourneyFormatterProps = {
  targets: Map<string, TargetStateRecord>,
  settings: JourneySettingsRecord,
  queries: Map<string, string>,
  messageIdsReplacement: Map<string, string>,
  rootNodeId: string,
  nodes: Map<string, JourneyNodeRecord>,
  message: MessageStateRecord,
  state: campaignStateType,
  name: string,
  labelCodes: Set<string>,
}

type OrchestrationJourneyFormatter = OrchestrationJourneyFormatterProps => OrchestrationJourney

const nodeToBlock: ({
  node: JourneyNodeRecord,
  firstEventName: string,
  messageIdsReplacement: Map<string, string>,
  quietTimes: ?QuietTimesRecord,
  targeting: Map<string, TargetStateRecord>,
  queries: Map<string, string>,
  hasCapping: boolean,
}) => Blocks = ({
  node,
  messageIdsReplacement,
  firstEventName,
  hasCapping,
  quietTimes,
  targeting,
  queries,
}) => {
  switch (node.type) {
    case 'FINAL':
      return {
        name: node.id,
        final: {},
      }
    case 'RANDOM':
      return {
        label: node.label ?? '',
        decorators: [],
        name: node.id,
        random: {
          splits: node.splits
            .map(split => {
              return { weight: split.weight, nextNodeId: split.nextNodeId }
            })
            .toArray(),
        },
      }
    case 'YESNO':
      return {
        label: node.label ?? '',
        decorators: [],
        name: node.id,
        yesNo: {
          yesNodeId: node.yesNodeId,
          noNodeId: node.noNodeId,
          branchingCriteria: {
            languages: targeting.get(node.id)?.languages.toArray(),
            regions: targeting.get(node.id)?.regions.toArray(),
            jsonQuery: queries.get(node.id, ''),
          },
        },
      }
    case 'MESSAGE': {
      let decorators = []
      if (hasCapping) {
        decorators.push({ capping: {} })
      }
      if (node.hasQuietTimes && quietTimes) {
        decorators.push({
          quiet: {
            behaviour: quietTimes.behavior === 'wait' ? 'WAIT' : 'SKIP',
            startHour: quietTimes.startHour,
            endHour: quietTimes.endHour,
            startMin: quietTimes.startMin,
            endMin: quietTimes.endMin,
            quietHoursTimePeriodDisabled: quietTimes.quietHoursTimePeriodDisabled,
            quietDaysOfWeek: quietTimes.quietDaysOfWeek.toArray(),
          },
        })
      }

      return {
        name: node.id,
        label: node.label ?? undefined,
        decorators,
        message: {
          nextNodeId: node.nextNodeId,
          messageReference: messageIdsReplacement.get(node.messageReference, node.messageReference),
        },
      }
    }
    case 'TIMER':
      return {
        name: node.id,
        decorators: [{ reset: {} }, { check: {} }],
        timer: {
          waitUntilTime:
            node.mode === 'until'
              ? {
                  hour: node.waitUntilTime.hour,
                  min: node.waitUntilTime.minute,
                  daysOfWeek: node.waitUntilTime.daysOfWeek.toArray(),
                }
              : undefined,
          nextNodeId: node.nextNodeId,
          // .000000001s = hack for negative value in proto duration
          timer:
            node.mode === 'until'
              ? undefined
              : `${node.mode === 'before' ? '-' : ''}${node.timer.seconds.toString()}${
                  node.mode === 'before' ? '.000000001s' : 's'
                }`,
          timerReference: node.timerReference
            ? {
                eventName: firstEventName,
                timerReferenceAttribute: node.timerReference,
              }
            : undefined,
          onEvents: node.onEvents
            .map(eventNextRec => {
              return {
                triggers: eventNextRec.triggers
                  .map(evt => {
                    return {
                      event: {
                        event: evt.name.replace('e.', ''),
                        ...(evt.query ? { jsonQuery: evt.query } : {}),
                      },
                      requireMatchingInstanceId: true,
                    }
                  })
                  .toArray(),
                nextNodeId: eventNextRec.nextNodeId,
              }
            })
            .toArray(),
        },
      }
  }
}
const parseInstanceId = (instanceId: string): OrchestrationJourneyInstanceId => {
  const regex = /eventAttr\(attr:\s?'(?<attr>\w*)'\)/gm
  const matches = regex.exec(instanceId)
  if (!matches || !matches.groups) return { type: 'LABEL', instanceIdFieldName: '' }
  return { type: 'ATTRIBUTE', instanceIdFieldName: matches.groups.attr }
}
export const formatOrchestrationJourney: OrchestrationJourneyFormatter = ({
  targets,
  nodes,
  rootNodeId,
  messageIdsReplacement,
  queries,
  message,
  settings,
  state,
  name,
  labelCodes,
}) => {
  /*
    timerReference (when a timer depends on a event date attribute) can only
    find the attribute when it knows where to look ; for now, we can't have multiple
    entry events with a timer reference, or a timer reference that relates to something
    else than an event we are sure the user did before entering this timer
  */
  const firstEventName = (settings.entryEvents.first()?.name ?? '').replace('e.', '')

  let nodesMap: { [string]: Blocks } = {}
  nodes.forEach((node, key) => {
    nodesMap[key] = nodeToBlock({
      node,
      firstEventName,
      messageIdsReplacement,
      quietTimes: settings.hasQuietTimes ? settings.quietTimes : null,
      hasCapping: settings.hasCapping,
      targeting: targets,
      queries,
    })
  })

  const globalTargeting = targets.get('default', TargetStateFactory())
  let messagesMap: {
    [string]: { email: EmailContent } | { sms: SmsContent } | { push: OrchestrationPush },
  } = {}
  nodes
    .filter(n => n.type === 'MESSAGE')
    .forEach(messageNode => {
      if (messageNode.type === 'MESSAGE') {
        const replacedId = messageIdsReplacement.get(
          messageNode.messageReference,
          messageNode.messageReference
        )
        if (messageNode.channel === 'push') {
          const msg: Map<langId, PushContentRecord> =
            message.push.get(messageNode.messageReference) ||
            new Immutable.Map<langId, PushContentRecord>()
          const first = msg.get('default', PushContentFactory())
          messagesMap[replacedId] = {
            push: formatPushMessage({
              priority: first.priority ?? 'PUSH_PRIORITY_UNSPECIFIED',
              ttl: first.ttlEnabled && first.ttl.valid ? `${first.ttl.seconds}s` : undefined,
              messages: msg,
            }),
          }
        }
        if (messageNode.channel === 'email') {
          const msg =
            message.email.get(messageNode.messageReference) ||
            Immutable.Map([['default', EmailContentFactory()]])
          messagesMap[replacedId] = {
            email: {
              target: globalTargeting.subscriptionStatus === 'marketing' ? 'Marketing' : 'Fullbase',
              localizedEmails: msg
                .map((email, language) => {
                  return {
                    ...(language && language !== 'default' ? { language } : {}),
                    message: {
                      subject: email.subject ?? '',
                      html: email.html ?? '',
                      replyTo: email.replyTo ?? undefined,
                      text: '',
                      from: {
                        address: {
                          email: email.fromEmail ?? '',
                          name: email.name ?? '',
                        },
                        senderIdentityId: email.senderIdentityId?.toString() ?? '',
                      },
                    },
                  }
                })
                .toArray()
                .map(kv => kv[1]),
            },
          }
        }
        if (messageNode.channel === 'sms') {
          const msg =
            message.sms.get(messageNode.messageReference) ||
            Immutable.Map([['default', SmsContentFactory()]])
          messagesMap[replacedId] = {
            sms: {
              target:
                globalTargeting.subscriptionStatus === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL',
              localizedSms: msg
                .map((sms, language) => {
                  return {
                    ...(language && language !== 'default' ? { language } : {}),
                    message: {
                      content: { text: sms.smsMessage },
                    },
                  }
                })
                .toArray()
                .map(kv => kv[1]),
            },
          }
        }
      }
    })
  return {
    settings: {
      name: name,
      startTime: settings.hasStart && settings.start ? settings.start.format() : undefined,
      endTime: settings.hasEnd && settings.end ? settings.end.format() : undefined,
      runningState:
        state === 'RUNNING'
          ? 'RUNNING_STATE'
          : state === 'STOPPED'
            ? 'STOPPED_STATE'
            : 'UNKNOWN_RUNNING_STATE',
      globalCapping: settings.hasCapping ? settings.capping : 0,
      globalGracePeriod: settings.hasGrace
        ? `${settings.gracePeriod.seconds.toString()}s`
        : undefined,
      targeting: {
        languages: globalTargeting.languages.toArray(),
        regions: globalTargeting.regions.toArray(),
        jsonQuery: queries.get('targeting') ?? undefined,
      },

      entryEvents: settings.entryEvents
        .map(evt => ({
          event: evt.name.replace('e.', ''),
          ...(evt.query ? { jsonQuery: evt.query } : {}),
        }))
        .toArray(),
      instanceId: settings.hasInstanceId ? parseInstanceId(settings.instanceId) : undefined,
      labels: labelCodes.toArray(),
    },

    messages: messagesMap,
    nodes: nodesMap,
    rootId: rootNodeId,
  }
}
