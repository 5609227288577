// @flow

import * as React from 'react'

export const hiddenFeatures = ['api-estimate', 'api-stats', 'images', 'query-transaction']
export const featureDescription: { [string]: React.Node, ... } = {
  'ab-testing': (
    <React.Fragment>
      <b>AB Testing</b> in push campaign form.
    </React.Fragment>
  ),
  'api-custom-events': (
    <React.Fragment>
      Ability to fire <b>events via the trigger event API</b>. Can only be used to trigger{' '}
      <b>trigger push campaigns</b>.
    </React.Fragment>
  ),
  'api-estimate':
    'Un-documented route of the stats API allowing a client to run estimate on their side. Used by only one customer.',
  'api-marketing': (
    <React.Fragment>
      API to create, view, edit <b>push campaign</b> and their statistics
    </React.Fragment>
  ),
  'api-profile': (
    <React.Fragment>API to update one or multiple profile's data and track events</React.Fragment>
  ),
  'api-stats': 'Unused',
  'api-transac': (
    <React.Fragment>
      API to create <b>transactionnal</b> push messages, and to view their statistics
    </React.Fragment>
  ),
  'api-project-export': (
    <React.Fragment>
      API to create profile/project export requests, view their status and download their results
    </React.Fragment>
  ),
  'app-data': (
    <React.Fragment>
      API to manage <b>app level data</b>, that can be used in message personnalization.
      <br />
      Uses cases:
      <br />
      <ul>
        <li style={{ marginLeft: 12 }}>
          Send an horoscope daily, with only one recuring campaign that will use the app level data
          (retrieve prediction for user zodiak sign)
        </li>
        <li style={{ marginLeft: 12 }}>
          Send election results with only one camppaign, fetching the result in the app data for the
          user city.
        </li>
      </ul>
      <p>API only, not visible on the dashboard</p>
    </React.Fragment>
  ),
  'app-user-restriction': 'Ability to restrict a user access to some apps of the company',
  capping_global: (
    <React.Fragment>
      Ability to use global frequency <b>cappings</b> (Global, Push campaigns &amp; Transactionnal
      push) for <b>mobile apps</b>
    </React.Fragment>
  ),
  capping_labels: (
    <React.Fragment>
      Ability to use Labels frequency <b>cappings</b> (require <code>label</code> feature) on push
      campaigns for <b>mobile apps</b>
    </React.Fragment>
  ),
  clusters: (
    <React.Fragment>
      Ability to use <b>smart segment</b> in targeting. Disabled on free plans.
    </React.Fragment>
  ),
  'custom-audience': (
    <React.Fragment>
      Ability to use <b>audiences</b> (via API or dashboard, and in targeting).
    </React.Fragment>
  ),
  'custom-payload': (
    <React.Fragment>
      Ability to set a<b>custom JSON payload</b> on push campaigns. Disabled on free plans.
    </React.Fragment>
  ),
  'export-events': (
    <React.Fragment>
      When enabled, <b>campaign activity is tracked</b> by data team (120 days of data is kept),
      allowing you to schedule exports from this console and customer to use the <b>Export API</b>.
      Can be expansive for big clients. Not retroactive.
    </React.Fragment>
  ),
  'export-userbase': (
    <React.Fragment>
      When enabled, <b>userbase snpashot</b> can be requested from this console or via the{' '}
      <b>Export API</b>.
    </React.Fragment>
  ),
  'email-campaign': (
    <React.Fragment>
      Temporary feature to restrict access to email campaign list and form.
    </React.Fragment>
  ),
  'sms-trigger': (
    <React.Fragment>
      Enable the SMS chan on the project. It also enables the Profile View as well as the
      Omnichannel tab if this is the first channel omni enabled.
    </React.Fragment>
  ),
  'email-recurring': (
    <React.Fragment>
      Temporary feature to restrict access to recurring email automation list and form.
    </React.Fragment>
  ),
  geoloc: (
    <React.Fragment>
      Ability to use <b>b.position</b> in campaign targeting query (the google map field).
    </React.Fragment>
  ),
  images: 'unused',
  imported: (
    <React.Fragment>
      Ability to see and use <b>imported</b> segment in campaign targeting and on userbase page.
    </React.Fragment>
  ),
  'inapp-campaigns': (
    <React.Fragment>
      Ability to create <b>in-app</b> campaigns.
    </React.Fragment>
  ),
  'inapp-capping': (
    <React.Fragment>
      Ability to use Labels frequency <b>cappings</b> (require <code>label</code> feature) on{' '}
      <b>in-app</b>
      campaigns for <b>mobile apps</b>
    </React.Fragment>
  ),
  'inapp-just-in-time': (
    <React.Fragment>
      When enabled, shows the checkbox <b>Re-evaluate targeting</b> on the targeting area of an
      in-app campaign ; if checked, SDK will make an API call to the backend to ensure targeting is
      still valid before displaying an in-app. More hits = more cost.
    </React.Fragment>
  ),
  'inapp-messaging': (
    <React.Fragment>
      Ability to use <b>mobile landings</b> on push campaign for mobile apps.
    </React.Fragment>
  ),
  'inapp-priority': (
    <React.Fragment>
      When enabled, user can specify a <b>priority</b> on an in-app campaign ; when multiple in-app
      campaigns could be displayed on a trigger, SDK will use set priority to decide which campaign
      to display.
    </React.Fragment>
  ),
  inbox: (
    <React.Fragment>
      When enabled, backend will record all notifications send to a user in the last 3 months
      period; This can be really <b>expensive</b> (storage space). Not retroactive. Allows customer
      to build a <b>notifications center</b>in their mobile app, so their users can easily retrieve
      a previously received commercial offer / discount.
    </React.Fragment>
  ),
  ingestion: (
    <React.Fragment>
      <b>Custom Data API</b> ; allows customer to use API to maanage user level data.
    </React.Fragment>
  ),
  label: (
    <React.Fragment>
      Allows user to set <b>labels</b> on their campaigns; can be usefull for categorization, and
      can be use with label cappings.
    </React.Fragment>
  ),
  macro: (
    <React.Fragment>
      Use custom data to <b>personnalize</b> message using our templating language.
    </React.Fragment>
  ),
  'query-attributes': (
    <React.Fragment>
      Ability to use <b>custom install data</b> in targeting (query) for <b>mobile apps</b>.
    </React.Fragment>
  ),
  'query-attributes-webpush': (
    <React.Fragment>
      Ability to use <b>custom install data</b> in targeting (query) for <b>web push</b>.
    </React.Fragment>
  ),
  'query-event-count': (
    <React.Fragment>
      Ability to use <b>event count</b> (user did e.add_to_cart 3 times) in targeting (query) for{' '}
      <b>mobile apps</b>. Require <code>query-events</code> feature.
    </React.Fragment>
  ),
  'query-event-count-period': (
    <React.Fragment>
      Ability to use <b>event count by period</b> (user did e.add_to_cart 3 times in the last 2
      months) in targeting (query) for <b>mobile apps</b>. Not retroactive. Expensive. Require{' '}
      <code>query-events</code> feature.
    </React.Fragment>
  ),
  'query-event-data': (
    <React.Fragment>
      Ability to use <b>event data</b> (user did e.add_to_cart with category = 'shoes') in targeting
      (query) for <b>mobile apps</b>. Require <code>query-events</code> feature.
    </React.Fragment>
  ),
  'query-events': (
    <React.Fragment>
      Ability to use <b>events</b> in targeting (query) for <b>mobile apps</b>. Require{' '}
      <code>query-events</code> feature.
    </React.Fragment>
  ),
  'query-natives': (
    <React.Fragment>
      Ability to use <b>native data</b> (provided by batch, b.install_date, etc...) in targeting
      (query).
    </React.Fragment>
  ),
  'query-retargeting': (
    <React.Fragment>
      Ability to use <b>retargeting</b> (user opeened campaign yolo) in targeting (query).
    </React.Fragment>
  ),
  'query-transaction': 'Unused ?',
  'fcm-v1-api-migration': (
    <React.Fragment>Ability to migrate to the FCM v1 API with Service Account Keys</React.Fragment>
  ),
  'trigger-campaigns': (
    <React.Fragment>
      Ability to create <b>trigger campaign</b> for <b>mobile apps</b>.
    </React.Fragment>
  ),
  webhook: (
    <React.Fragment>
      Ability to use <b>webhooks</b>: see documentation to learn more. Setup is manual.
    </React.Fragment>
  ),
  webpush: 'Ability to create website from the dashboard.',
  webpush_capping_global: (
    <React.Fragment>
      Ability to use global frequency <b>cappings</b> (Global, Push campaigns &amp; Transactionnal
      push) for <b>web push</b>
    </React.Fragment>
  ),
  webpush_capping_labels: (
    <React.Fragment>
      Ability to use Labels frequency <b>cappings</b> (require <code>label</code> feature) on push
      campaigns for <b>web push</b>
    </React.Fragment>
  ),
  'webpush-trigger-campaigns': (
    <React.Fragment>
      Ability to create <b>trigger campaign</b> for <b>web push</b>.
    </React.Fragment>
  ),
  'cep-sms-campaign': (
    <React.Fragment>
      Ability to create <b>SMS campaign</b> from the CEP dashboard.
    </React.Fragment>
  ),
  'cep-sms-recurring': (
    <React.Fragment>
      Ability to create <b>recurring SMS campaign</b> from the CEP dashboard.
    </React.Fragment>
  ),
  'cep-push-campaign': (
    <React.Fragment>
      Ability to create <b>push campaign</b> from the CEP dashboard.
    </React.Fragment>
  ),
  'cep-push-recurring': (
    <React.Fragment>
      Ability to create <b>recurring push campaign</b> from the CEP dashboard.
    </React.Fragment>
  ),
  'cep-show-legacy-campaign': (
    <React.Fragment>
      CEP interface : show <b>legacy form &amp; campaign listing</b>. Will also enable related
      settings screens (audiences, labels, capping). Landings depends on the mobile-landing feature.
    </React.Fragment>
  ),
  'cep-show-legacy-campaign-omni': (
    <React.Fragment>
      CEP interface : show <b>legacy form &amp; campaign listing</b> AND{' '}
      <b>fake omnichannel form</b>. Will also enable related settings screens (audiences, labels,
      capping). Landings depends on the mobile-landing feature.
    </React.Fragment>
  ),
  'cep-show-legacy-recurring': (
    <React.Fragment>
      CEP interface : show <b>legacy recurring form &amp; automation listing</b>. Will also enable
      related settings screens (audiences, labels, capping). Landings depends on the mobile-landing
      feature.
    </React.Fragment>
  ),
  'cep-show-legacy-trigger': (
    <React.Fragment>
      CEP interface : show <b>legacy recurring form &amp; automation listing</b>. Will also enable
      related settings screens (audiences, labels, capping). Landings depends on the mobile-landing
      feature.
    </React.Fragment>
  ),
  'cep-only': (
    <React.Fragment>
      Users will be redirected to <b>CEP interface upon</b> login, and the switch to app view button
      will be hidden. Accessing an MEP URL will still work.
    </React.Fragment>
  ),
}
