// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  HeaderBoxActions,
  HeaderBoxTitle,
  BoxHeader,
  BoxBody,
  BoxSection,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { SideSheet } from 'components/common/sidesheet'
import { Icon } from 'components/common/svg-icon'
import { Targeting } from 'components/project/campaign/common/targeting'
import { EmailWhenTiming } from 'components/project/campaign/common/when/when-timing'

import { NodeIconWrapper } from './node-icon-wrapper'
import { QuietTimesBlock } from './quiet-time'
import { SheetSettingsTabs, sheetWidthsForTabs } from './sheet-settings-tab'
import { TargetingWrapperTrigger } from './sheet-settings.styles'

import { type settingsTabKind } from '../pages/journey-canvas-form'
import { estimateProfileGetterSelector } from 'com.batch.redux/target/target.selector'

import { EmailWhen } from 'com.batch/email/when'
import { EstimateProfile } from 'com.batch/orchestration/ui/components/estimate-profile'

type SheetSettingsProps = {
  isOpened: boolean,
  dismiss: () => void,
  tab: settingsTabKind,
  setSettingsTab: (tab: settingsTabKind) => void,
  refocusOnNode: ({ width?: number, zoom?: number }) => void,
}

export const SheetSettings = ({
  isOpened,
  dismiss,
  tab,
  setSettingsTab,
  refocusOnNode,
}: SheetSettingsProps): React.Node => {
  const estimateProfileGetter = useSelector(estimateProfileGetterSelector)
  const estimate = React.useMemo(() => estimateProfileGetter('default'), [estimateProfileGetter])

  const handleSelectTab = React.useCallback(
    (tab: settingsTabKind) => () => {
      setSettingsTab(tab)
    },
    [setSettingsTab]
  )

  const sheetWidth = React.useMemo(() => sheetWidthsForTabs[tab], [tab])
  React.useEffect(() => {
    if (isOpened) refocusOnNode({ width: sheetWidth })
  }, [refocusOnNode, sheetWidth, isOpened])

  return (
    <SideSheet
      isOpened={isOpened}
      close={dismiss}
      footerActions={
        <Button kind="secondary" onClick={dismiss}>
          Continue
        </Button>
      }
      width={sheetWidth}
    >
      <BoxHeader style={{ height: 52 }}>
        <HeaderBoxTitle
          title={<NodeIconWrapper kind="entry">Automation settings</NodeIconWrapper>}
        />
        <HeaderBoxActions large>
          <Button onClick={dismiss}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody>
        <BoxSection style={{ padding: 8 }}>
          <SheetSettingsTabs tab={tab} handleSelectTab={handleSelectTab} />
        </BoxSection>
        {tab === 'targeting' && (
          <TargetingWrapperTrigger>
            <Targeting isProfile canvasMode step="enter" id="default" />

            <EstimateProfile estimate={estimate} />
          </TargetingWrapperTrigger>
        )}
        {tab === 'timings' && (
          <div style={{ padding: '28px 20px' }}>
            <EmailWhenTiming tab={tab} />
          </div>
        )}
        {tab === 'enter' && (
          <div style={{ padding: '28px 20px' }}>
            <EmailWhen tab={tab} />
          </div>
        )}
        {tab === 'quiet-hours' && <QuietTimesBlock />}
      </BoxBody>
    </SideSheet>
  )
}
