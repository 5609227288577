// @flow
import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { isEmail, textUsesTemplating } from 'com.batch.common/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'
import { messageStateSelector } from 'com.batch/message/store/message.selector'
import { type OrchestrationStateRecord } from 'com.batch/orchestration/store/orchestration.state'
import { getPushContentError } from 'com.batch/push/store/push.selector.helper'
import { type fetchingState, type pushCampaignSendType, type State } from 'com.batch.redux/_records'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { type MessageNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'
import {
  journeyErrorDraftSelector,
  journeyErrorSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'

type Extract<T> = State => T

export const orchestrationStateSelector: Extract<OrchestrationStateRecord> = state =>
  state.orchestration

const CAMPAIGNS_SENDTYPES = ['scheduled', 'now']

export const orchestrationMetaSelector: Extract<{
  id: string,
  loadingState: fetchingState,
  name: string,
  schedulingType: schedulingType,
  sendType: pushCampaignSendType,
  state: campaignStateType,
  version: number,
  savedVersion: number,
}> = createSelector(orchestrationStateSelector, os => ({
  id: os.id,
  loadingState: os.loadingState,
  name: os.name,
  schedulingType: CAMPAIGNS_SENDTYPES.includes(os.campaign.sendType) ? 'campaigns' : 'automations',
  sendType: os.campaign.sendType,
  state: os.state,
  version: os.version,
  savedVersion: os.savedVersion,
}))

export const orchestrationCampaignSelector: Extract<OrchestrationCampaignRecord> = state =>
  state.orchestration.campaign

export const campaignDraftErrorSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  ({ name }) => {
    const err: Array<string> = []
    if (!name) err.push('Enter a name to save.')
    return new Immutable.List().push(...err)
  }
)

export const orchestrationHashSelector: Extract<number> = createSelector(
  orchestrationStateSelector,
  orchestration => orchestration.hashCode()
)

export const campaignErrorSelector: Extract<List<string>> = createSelector(
  orchestrationCampaignSelector,
  messageStateSelector,
  campaignDraftErrorSelector,
  (campaign, content, errors) => {
    const err: Array<string> = errors.toArray()
    if (campaign.sendType === 'scheduled') {
      if (!campaign.oneTime.sendDate) err.push('Send date is missing.')
      if (campaign.oneTime.sendDate && campaign.oneTime.sendDate.isBefore(dayjs.utc()))
        err.push('Send date is in the past.')
    }
    if (campaign.sendType === 'recurring') {
      if (!campaign.recurrent.startDate)
        err.push('Recurring automation requires a valid start date.')
      if (campaign.recurrent.startDate && campaign.recurrent.startDate.isBefore(dayjs.utc()))
        err.push('Start date is in the past.')
      if (campaign.recurrent.endDate && campaign.recurrent.endDate.isBefore(dayjs.utc()))
        err.push('End date is in the past.')
      if (
        campaign.recurrent.startDate &&
        campaign.recurrent.endDate &&
        campaign.recurrent.endDate.isBefore(campaign.recurrent.startDate)
      )
        err.push('End date is before start date.')
    }

    const channel = campaign.channel

    if (channel === 'email') {
      const contentEmail = content.email.getIn([campaign.messageTypedId, 'default'])
      if (!contentEmail) {
        err.push('Email content is missing.')
      } else {
        if (!contentEmail.html) err.push('Email content is missing.')
        if (!contentEmail.senderIdentityId) err.push('Sender identity is missing.')
        if (!contentEmail.subject) err.push('Subject is missing.')
        if (
          contentEmail.replyTo &&
          !isEmail(contentEmail.replyTo) &&
          !textUsesTemplating(contentEmail.replyTo)
        )
          err.push('Reply-to must be a valid email address.')
      }
    }

    if (channel === 'push') {
      const contentPush = content.push.getIn([campaign.messageTypedId, 'default'])
      if (!contentPush) {
        err.push('Push content is missing.')
      } else {
        err.push(...getPushContentError(contentPush))
      }
    }

    if (channel === 'sms') {
      const contentSms = content.sms.getIn([campaign.messageTypedId, 'default'])
      if (!contentSms) {
        err.push('SMS content is missing.')
      } else {
        if (!contentSms.smsMessage) err.push('SMS content is missing.')
      }
    }

    return new Immutable.List().push(...err)
  }
)

export const orchestrationErrorsSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  journeyErrorSelector,
  campaignErrorSelector,
  ({ sendType }, journeyErr, campaignErr) => (sendType === 'trigger' ? journeyErr : campaignErr)
)

export const orchestrationDraftSelector: Extract<List<string>> = createSelector(
  orchestrationMetaSelector,
  journeyErrorDraftSelector,
  campaignDraftErrorSelector,
  ({ sendType }, journeyDraftErr, campaignDraftErr) =>
    sendType === 'trigger' ? journeyDraftErr : campaignDraftErr
)

export const orchestrationLabelSelector: Extract<List<LabelRecord>> = createSelector(
  orchestrationStateSelector,
  labelsSelector,
  (orchestration, labelsState) =>
    orchestration.labelCodes.toList().map(code => {
      const match = labelsState.labels.find(label => label.code === code)
      return match
        ? match
        : LabelFactory({
            code,
            isDeleted: true,
          })
    })
)

export const messageNodesSelector: Extract<Map<string, MessageNodeRecord>> = createSelector(
  orchestrationStateSelector,
  (orchestration: OrchestrationStateRecord) =>
    // $FlowIgnore Notre version de flow ne supporte pas les TypeGuards `(node: JourneyNodeRecord): node is MessageNodeRecord =>`
    orchestration.triggerNodes.filter(node => node.type === 'MESSAGE')
)

export const orchestrationIDSelector: Extract<string> = createSelector(
  orchestrationStateSelector,
  orchestration => orchestration.id
)
