// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Input, InputWrapper, Radio } from 'components/form'

import { updatePushContentRecord } from '../usecases/update-push-content'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { type AgeRecord } from 'com.batch.redux/_records'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { activeLanguageValueSelector } from 'com.batch.redux/campaign.selector'

import {
  type AndroidPushMessageRawRecord,
  type PushContentRecord,
  type PushPriority,
} from 'com.batch/message/models/message.records'
import { PushPayloadField } from 'com.batch/push/ui/push-payload-field'
import { MessageQuietTimes } from 'com.batch/shared/ui/component/message-quiet-time'

type PushAdvancedSettingsProps = {
  messageId: string,
  stepId?: string,
}

export const PushAdvancedSettings = ({
  messageId,
  stepId,
}: PushAdvancedSettingsProps): React.Node => {
  const dispatch = useDispatch()
  const lang = useSelector(activeLanguageValueSelector)
  const apps = useSelector(currentProjectAppsSelector)
  const getContent = useSelector(getPushContentForActiveLanguageSelector)

  const hasAndroid = React.useMemo(() => apps.some(app => app.platform === 'android'), [apps])
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])

  const updatePushSettings = React.useCallback(
    (record: PushContentRecord) => {
      dispatch(updatePushContentRecord({ messageId, lang, record }))
    },
    [dispatch, messageId, lang]
  )

  const handlePriorityChange = React.useCallback(
    (value: PushPriority) => () => {
      updatePushSettings(content.set('priority', value))
    },
    [updatePushSettings, content]
  )

  const handleTtlToggle = React.useCallback(
    (bool: boolean) => {
      updatePushSettings(content.set('ttlEnabled', bool))
    },
    [updatePushSettings, content]
  )

  const handleTtlChange = React.useCallback(
    (ttl: AgeRecord) => {
      updatePushSettings(content.set('ttl', ttl))
    },
    [updatePushSettings, content]
  )

  const handleAndroidSettingsChange = React.useCallback(
    (field: $Keys<AndroidPushMessageRawRecord>) =>
      (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updatePushSettings(content.setIn(['androidContent', field], e.currentTarget.value))
      },
    [updatePushSettings, content]
  )

  return (
    <div style={{ padding: 20 }}>
      <MessageQuietTimes channel="push" stepId={stepId} />
      <Grid template="1fr 1fr" alignItems="start" gap={40}>
        <div>
          <PrioritySection priority={content.priority} onPriorityChange={handlePriorityChange} />
          {hasAndroid && (
            <AndroidSection
              androidContent={content.androidContent}
              onAndroidSettingsChange={handleAndroidSettingsChange}
            />
          )}
          <ExpirationSection
            ttl={content.ttl}
            hasTtl={content.ttlEnabled}
            onTtlToggle={handleTtlToggle}
            onTtlChange={handleTtlChange}
          />
        </div>
        <PushPayloadField messageId={messageId} />
      </Grid>
    </div>
  )
}

const PrioritySection = ({
  priority,
  onPriorityChange,
}: {
  priority: PushPriority,
  onPriorityChange: (p: PushPriority) => Function,
}) => (
  <InputWrapper
    label="Priority"
    style={{ marginBottom: 24 }}
    hintMinSize={400}
    hint={
      <div style={{ textAlign: 'left' }}>
        Default is high. Normal priority send the push message at a time that takes into account
        power considerations for the device. Notifications with this priority might be grouped and
        delivered in bursts. They are throttled, and in some cases are not delivered.
      </div>
    }
  >
    <Radio
      label="Normal"
      style={{ marginRight: 20 }}
      checked={priority === 'PUSH_PRIORITY_NORMAL'}
      onChange={onPriorityChange('PUSH_PRIORITY_NORMAL')}
    />
    <Radio
      label="High"
      checked={priority === 'PUSH_PRIORITY_HIGH'}
      onChange={onPriorityChange('PUSH_PRIORITY_HIGH')}
    />
  </InputWrapper>
)

const AndroidSection = ({
  androidContent,
  onAndroidSettingsChange,
}: {
  androidContent: AndroidPushMessageRawRecord,
  onAndroidSettingsChange: (field: $Keys<AndroidPushMessageRawRecord>) => Function,
}) => (
  <InputWrapper
    style={{ marginBottom: 24 }}
    hint={
      <div style={{ textAlign: 'left' }}>
        <p style={{ marginBottom: 8 }}>
          Defines how notifications are managed when an offline device goes online. If you specify
          keys, the device will only show the most recent notification.
        </p>
        <p style={{ marginBottom: 8 }}>
          If you don't specify keys, it will show all the notifications received when the device was
          offline.
        </p>
        <p style={{ marginBottom: 8 }}>
          You should not fill this field if all your notifications matter (E.g. messages, etc).
        </p>
        <p>
          You can use up to 3 different collapse keys if you want users to get only one notification
          of each kind when coming online (E.g. marketing, alert, etc).
        </p>
      </div>
    }
    hintMinSize={400}
    label={
      <span>
        <Icon icon="android" style={{ marginRight: 8 }} /> FCM Collapse key
      </span>
    }
  >
    <Input
      value={androidContent?.collapseKey}
      onChange={onAndroidSettingsChange('collapseKey')}
      placeholder="Default, marketing, ... (optional)"
    />
  </InputWrapper>
)

const ExpirationSection = ({
  ttl,
  hasTtl,
  onTtlToggle,
  onTtlChange,
}: {
  ttl: AgeRecord,
  hasTtl: boolean,
  onTtlToggle: boolean => void,
  onTtlChange: AgeRecord => void,
}) => (
  <InputWrapper
    hintMinSize={400}
    hint={
      <div style={{ textAlign: 'left' }}>
        By default, Batch will not set notifications to expire, which means that if the user's
        device is offline for 2 weeks and then goes back online, it will receive all notifications
        sent during this period. You can change this behavior here.
      </div>
    }
    label={
      <Switch isActive={hasTtl} onChange={onTtlToggle}>
        Expiration (TTL)
      </Switch>
    }
  >
    {hasTtl && (
      <TimeIntervalInput
        age={ttl}
        onChange={onTtlChange}
        style={{ width: 200 }}
        allowedUnits={['m', 'h', 'd']}
        min={60}
        max={3600 * 24 * 28}
      />
    )}
  </InputWrapper>
)
